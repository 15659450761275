* {
  box-sizing: border-box;
}

body {
  background-color: #F6F4F1;
}

@media (prefers-color-scheme: dark) {
  body {
    background-color: #423E3A
  }
}

.App {
  text-align: center;
  display: flex;
  flex-direction: column;
  align-items: center;
}

/* This is a generic section with 100% width so the background color can be
   edge to edge but the content is limited by the AppContentWidth below. */
.AppSection {
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.AppSectionContent {
  max-width: min(1366px, 100vw);
}

.HorizontalFlexBox {
  display: flex;
  flex-direction: row;
}

.VerticalFlexBox {
  display: flex;
  flex-direction: column;
}

.Flex1 {
  flex: 1;
}

.BeigeSection {
  background-color: #F6F4F1;
  color: black;
}

.WhiteSection {
  background-color: #FFF;
  color: black;
}

.RedSection {
  background-color: #96695e;
  color: white;
}

.LightRedSection {
  background-color: #cb8e7e;
  color: black;
}


@media (prefers-color-scheme: dark) {
  .BeigeSection {
    background-color: #423E3A;
    color: white;
  }

  .BeigeSection a {
    color: white;
  }
}

.DarkSection {
  background-color: #423E3A;
  color: white;
}

.BlackSection {
  background-color: #000;
  color: white;
}

@media (prefers-color-scheme: dark) {
  .DarkSection {
    background-color: #000;
    color: white;
  }
}

.DarkSection a {
  color: white;
}

.Header {
  display: flex;
  flex: 1;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 20px;
  gap: 42px;
}

.WaitlistHeader {
  position: sticky;
  top: 0;
  border-bottom: 1px solid #423E3A80;
}

.HeaderLeftElement {
  display: flex;
  flex-direction: row;
  justify-items: start;
  justify-content: start;
  align-items: center;
  font-size: 32px;
  padding-left: 8px;
  gap: 5px;
  font-weight: 200;
  font-family: 'Inter', sans-serif;
  flex-grow: 2;
}

.HeaderLogo {
  width: min(160px, 25vw);
}

@media (prefers-color-scheme: dark) {
  .HeaderLeftElement {
    filter: invert(100%);
  }
}

.HeaderRightElement {
  flex-grow: 0;
  margin: 0;
  padding: 0;
}

.ProfileButton {
  width: 46px;
  height: 46px;
}

.StandardButton {
  font-family: 'Nunito', sans-serif;
  font-size: 16pt;
  font-weight: bold;
  background: none;
  border: none;
  cursor: pointer;
  color: black;
  text-decoration: none;
}

.CalloutButton {
  font-family: 'Nunito', sans-serif;
  font-size: 16pt;
  font-weight: bold;
  background: black;
  border: none;
  cursor: pointer;
  color: white;
  border-radius: 8px;
  padding: 12px 36px 12px 36px;
}

/* "The first main section under the header, "Welcome to a Healthier you" */
.WelcomeSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin: 20pt;
}

.WelcomeSection h1 {
  font-size: 64pt;
  font-weight: normal;
  margin-top: 10px;
  margin-bottom: 0;
  font-family: 'Sorts Mill Goudy', sans-serif;
}

@media (max-width: 500px) {

  .WelcomeSection {
    padding: 40px;
  }

  .WelcomeSection h1 {
    font-size: 34pt;
  }

  .WelcomeSection h2 {
    font-size: 12pt;
  }
}

.WelcomeSection h2 {
  font-size: 20pt;
  font-weight: 300;
  margin-top: 0;
  margin-bottom: 10px;
  font-family: 'Nunito', sans-serif;
}

.TextInputStyle {
  border: 1px solid gray;
  border-radius: 5px;
  background: white url('../images/icons/search_bg.png') center;
  background-size: contain;
  height: 71px;
  width: 784px;
  font-family: 'Nunito', sans-serif;
  font-size: 20pt; /* 16 px required for ios to not zoom in! */
  text-align: center;
  margin-top: 10px;
  margin-bottom: 10px;
}

/* 
   The div that is the parent of the overlay, needs to be relative position.
   This is right below the text input 
*/
.OverlayPositioner {
  position: relative;
  width: 790px;
}

.Overlay {
  border: 1px solid gray;
  border-radius: 5px;
  width: 768px;
  position: absolute;
  display: flex;
  flex-direction: column;
  font-family: 'Nunito', sans-serif;
  font-size: 20pt; /* 16 px required for ios to not zoom in! */
  text-align: center;
  gap: 25px;
  padding: 10px;
  top: 0;
  left: 0;
  background-color: #FFFFFF;
}

.OverlayElement {
  padding: 10px;
  text-align: start;
  background-color: #fff;
}

.OverlayElement:hover {
  background-color: #eee;
}

.WhatIsKenkoSection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  font-family: 'Nunito', sans-serif;
  font-weight: 300;
  font-size: 18pt;
}

.WhatIsKenkoLeft {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: start;
  /* 120 all around but accounting for padding on h2 (47px) and p (24px) */
  padding: 73px 120px 96px 120px;
}

.WhatIsKenkoRight {
  flex: 1;
  /*min-width: 25vh;*/
  min-height: 600px;
  display: flex;
  justify-content: center;
  background-image: url("../images/home_splash_2.png");
  background-position: 25% 100%;
}

.WhatIsKenkoRightImage {
  flex: 1;
  width: 100%;
  align-self: center;
  overflow: hidden;
}

@media (max-width: 800px) {
  .WhatIsKenkoSection {
    flex-direction: column-reverse;
  }

  .WhatIsKenkoRight {
    min-height: 40vh;
    background-size: 100%;
    background-position: 25% 90%;
  }
}

.WhatIsKenkoSection h2 {
  font-weight: normal;
  font-size: 42pt;
  font-family: 'Sorts Mill Goudy', sans-serif;
  margin-bottom: 12px;
}

@media (max-width: 1000px) {
  .WhatIsKenkoLeft {
    /* 40px is the target paddin*/
    padding: 40px;
  }

  .WhatIsKenkoSection h2 {
    font-weight: normal;
    font-size: 28pt;
    margin-top: 0;
  }
}

.CategoriesSection {
  flex: 1;
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: center;
  align-items: center;
  gap: 20px;
  margin-top: 109px;
  margin-bottom: 109px;
}

.CategoryIcon {
  width: 277px;
  height: 263px;
  display: flex;
  flex-direction: column;
  gap: 30px;
  font-family: 'Nunito', sans-serif;
  font-size: 20pt;
  align-items: center;
  justify-content: center;
  color: #000;
  background-color: #fff;
  border-radius: 10px;
  overflow: hidden;
}

.FoundersSection {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap-reverse;
  font-family: 'Nunito', sans-serif;
  font-size: 18pt;
}

.FoundersSection h2 {
  font-weight: normal;
  font-size: 42pt;
  font-family: 'Sorts Mill Goudy', sans-serif;
}

.FoundersSectionLeft {
  flex: 1;
  display: flex;
}

.FoundersSectionRight {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: start;
  /* 120 all around but accounting for padding on h2 (47px) and p (24px) */
  padding: 73px 120px 96px 120px;
}

.FoundersImage {
  width: 45vw;
  align-self: center;
}

@media (max-width: 1000px) {
  .FoundersImage {
    width: 100vw;
  }
}


.FoundersJoinUs {
  width: 231px;
  height: 57px;
  margin-top: 36px;
  margin-bottom: 24px;
  font-size: 16pt;
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  border-radius: 8px;
  color: white;
}

/* lays the articles in a row for wide layouts, column for narrow */
.ArticlePreviewSection {
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: center;
  text-align: start;
  margin: 126px 30px 20px;
  gap: 80px;
}

.ArticlePreview {
  flex: 1;
  display: flex;
  flex-direction: column;
  max-width: 320px;
  font-family: 'Sorts Mill Goudy', sans-serif;
  font-size: 20pt;
  font-weight: normal;
}

.ArticlePreviewCaption {
  display: flex;
  flex-direction: column;
}

.ArticlePreviewImage {
  max-height: 280px;
  max-width: 40vw;
  margin-bottom: 20px;
}


@media (max-width: 1000px) {
  .ArticlePreviewSection {
    flex-direction: column;

  }

  .ArticlePreview {
    max-width: 1000px;
    flex-direction: row;
    align-items: start;
  }

  .ArticlePreviewCaption {
    display: flex;
    flex-direction: column;
    justify-content: start;
  }

  .ArticlePreviewCaption p {
    margin-top: 0;
  }

  .ArticlePreviewImage {
  }
}

@media (max-width: 500px) {
  .ArticlePreviewSection {
    margin: 0;
    padding: 40px;
  }

  .ArticlePreview {
    font-family: 'Sorts Mill Goudy', sans-serif;
    font-size: 14pt;
  }

  .ArticlePreviewImage {
    max-height: 30vw;
  }
}

a.ArticlePreview {
  color: black;
}

@media (prefers-color-scheme: dark) {
  a.ArticlePreview {
    color: white;
  }
}

.ArticlePreviewTinyText {
  font-family: 'Nunito', sans-serif;
  font-size: 10pt;
  font-weight: bold;
}

a.ArticlePreview {
  gap: 25px;
  text-decoration: none;
}

.ArticlePreviewText {
  text-decoration: none;
  margin-top: 5px;
}

.Footer {
  margin: 50px;
  min-height: 200px;
  flex: 1;
  display: flex;
  flex-direction: column;
}

.FooterTopRow {
  flex: 1;
  margin-bottom: 25px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.FooterLogo {
  width: min(180px, 25vw);
  filter: invert(100%);
}

.FooterLeft {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
}

.FooterMiddleRow {
  display: flex;
  flex-direction: row;
  font-family: 'Nunito', sans-serif;
  font-size: 14pt;
  margin-top: 25px;
  margin-bottom: 25px;
  min-width: 300px;
  justify-content: end;
}

.FooterRightColumn {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 4px;
  text-align: start;
}

.FooterRightColumn a {
  color: white;
}

.FooterBottomRow {
  flex: 1;
  align-self: start;
  margin-top: 25px;
  margin-bottom: 0;
  font-family: 'Nunito', sans-serif;
  font-size: 12pt;
  color: white;
  text-align: start;
}

@media (max-width: 600px) {
  .FooterMiddleRow {
    flex-direction: column;
    gap: 30px;
  }
}

.ChatPage {
  flex: 1;
  display: flex;
  flex-direction: row;
  font-family: 'Nunito', sans-serif;
  font-size: 16pt;
}

.ChatPageLeft {
  flex: 1;
  display: flex;
  flex-direction: column;
  text-align: left;
  margin: 40px;
  gap: 10px;
}

.ChatPageRight {
  flex: 2;
  display: flex;
  flex-direction: column;
  background-color: white;
  min-height: 500px;
}

.ChatPageInput {
  align-self: center;
  font-size: 16pt;
  height: 40px;
  width: 400px;
  text-align: center;
  margin-bottom: 10px;
}

.NewConversationButton {
  width: 294px;
  height: 69px;
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: black;
  color: white;
  border-radius: 8px;
  margin-bottom: 30px;
}

.ChatRow {
  display: flex;
  flex-direction: row;
  gap: 20px;
  margin: 37px;
  padding: 5px 0 5px 0;
  align-items: start;
}

.ChatRowUser {
  justify-content: end;
}

.ChatInitial {
  height: 54px;
  width: 54px;
  margin-top: 10px;
  border: 1px solid black;
  border-radius: 27px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ChatInitialUser {
  background-color: #423E3A;
  color: white;
}

.ChatMessage {
  border-radius: 8px;
  max-width: 70%;
  text-align: start;
  padding-left: 30px;
  padding-right: 30px;
}

.ChatMessageAssistant {
  align-self: start;
}

.ChatMessageUser {
  background-color: #423E3A;
  color: white;
  align-self: end;
}

.InvertedColors {
  background-color: black;
  color: white;
  padding: 5px 10px 5px 10px;
}

.ChatMarkdown a {
  color: black;
}

.AboutKenkoSection {
  margin: 70px;
  max-width: 783px;
  font-family: 'Nunito', sans-serif;
  font-size: 16pt;
}

.AboutKenkoSection h2 {
  font-family: 'Sorts Mill Goudy', sans-serif;
  font-weight: normal;
  font-size: 24pt;
}

.DisclaimerSection {
  margin: 40px;
  max-width: 1090px;
  text-align: start;
  font-family: 'Nunito', sans-serif;
  font-size: 12pt;
  font-style: italic;
}

.SocialLinks {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 10px;
}

.PrivacyPage {
  padding: 40px;
  font-size: 16pt;
  font-family: 'Nunito', sans-serif;
  text-align: left;
}

.PrivacyPage h1 {
  font-family: 'Sorts Mill Goudy', sans-serif;
  font-weight: normal;
}

.PrivacyPage h2 {
  margin-top: 40px;
  font-family: 'Sorts Mill Goudy', sans-serif;
  font-weight: normal;
}
