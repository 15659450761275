.BookingOverlayModal {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background: #00000088;
}

.BookingOverlay {
  position: static;
  display: flex;
  flex-direction: column;
  width: 1200px;
  height: 660px;
  border-radius: 8px;
  background: #FFFFFF;
  opacity: 100%;
}

.BookingOverlayTopRow {
  display: flex;
  align-items: center;
  height: 20%;
  width: 100%;
  font-family: 'Sorts Mill Goudy', sans-serif;
  font-size: 30pt;
  text-align: start;
  border-bottom: 2px solid black;
}

.HorizontalCenteredFlex {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
}

.BookingOverlayBottomRow {
  display: flex;
  flex-direction: row;
  font-family: 'Nunito', sans-serif;
  font-size: 20pt;
  height: 80%;
}

.BookingOverlayBottomLeft {
  flex: 1;
  text-align: start;
  height: 100%;
  border-right: 2px solid black;
}

.BookingOverlayBottomRight {
  flex: 2;
  height: 100%;
  overflow: scroll;
}

.BookingOverlayDate {
  text-align: start;
  font-weight: bold;
  margin-top: 20px;
  margin-bottom: 20px;
}

.BookingOverlayTimeSlotsContainer {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  gap: 24px;
  overflow: auto;
}

.BookingOverlayTimeSlot {
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Nunito', sans-serif;
  font-size: 20pt;
  width: 146px;
  height: 67px;
  background: none;
  border: 2px solid #96695e;
  border-radius: 8px;
}

.BookingOverlayTimeSlot:hover {
  background: #423E3A;
  color: white;
  cursor: pointer;
}

.BookingOverlayTimeSlot a {
  color: black;
  text-decoration: none;
}

.BookingOverlaySectionMargin {
  flex: 1;
  margin: 30px;
}

.BookingOverlayClose {
  background: none;
  border: none;
  height: 32px;
  width: 32px;
  cursor: pointer;
}

.ConfirmationBottomSection {
  flex: 1;
  display: flex;
  flex-direction: column;
  gap: 12px;
}

.ConfirmationRow {
  display: flex;
  flex-direction: row;
  align-items: center;
  margin: 10px;
}

.ConfirmationRowLeftIcon {
  width: 60px;
  margin-right: 20px;
}

.ConfirmationRowRight {
  display: flex;
  flex-direction: column;
  align-items: start;
}
.ConfirmationRowLeftImage {
  width: 40px;
}

.ConfirmationButton {
  font-family: 'Nunito', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 10px;
  width: 632px;
  height: 63px;
  font-size: 20pt;
  font-weight: bold;
  border: 2px solid #96695e;
  border-radius: 8px;
  cursor: pointer;
}

.ConfirmationButtonBlack {
  background-color: black;
  color: white;
}

.CancelButton {
  font-family: 'Nunito', sans-serif;
  width: 634px;
  height: 67px;
  font-size: 20pt;
}
