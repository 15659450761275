@media (prefers-color-scheme: dark) {
  .ComingSoonBanner {
    /*background-color: #000;*/
    /*color: #fff;*/
  }
}

.EmailSignupBox {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  row-gap: 20px;
}

.EmailSignupBoxGap20 {
  gap: 20px;
}

.EmailSignupText {
  width: min(531px, 90vw);
  height: 70px;
  padding: 0;
  margin: 0;

  border-top-left-radius: 8px;
  border-bottom-left-radius: 8px;
  border: 1px solid black;
  text-align: center;

  font-family: 'Nunito', sans-serif;
  font-size: 16pt;
  box-sizing: border-box;
}

.EmailSignupButton {
  width: 252px;
  height: 70px;
  padding: 0;
  margin: 0;

  font-family: 'Nunito', sans-serif;
  font-size: 16pt;

  border-bottom-right-radius: 8px;
  border-top-right-radius: 8px;
  border: 1px solid black;
  border-block-end-width: 0;

  /* ios safari does funny things rounding buttons! */
  -webkit-appearance: none;
  
  font-weight: bold;
  background-color: #000000;
  color: #ffffff;
}

@media (max-width: 800px) {
  .EmailSignupBox {
    flex-direction: column;
  }

  .EmailSignupText {
    border-radius: 8px;
  }
  
  .EmailSignupButton {
    border-radius: 8px;
  }
}
