.ArticleSection {
  font-family: 'Nunito', sans-serif;
  font-size: 16pt;
  text-align: start;
  padding: 64px 64px 20px 64px;
}


.ArticleSection h1 {
  font-family: 'Sorts Mill Goudy', sans-serif;
  font-size: 40pt;
  font-weight: normal;
  margin-top: 20px;
}

.ArticleSection h2 {
  font-family: 'Sorts Mill Goudy', sans-serif;
  font-size: 24pt;
  font-weight: normal;
}

.ArticleSection li {
  margin: 10px 5px 20px 5px;
}

.ArticleSocialLinks {
  flex: 1;
  padding: 0px 60px 20px 60px;
}

.SocialLink {
  width: 35px;
  height: 35px;
}

.ArticleImage {
  width: 100%;
  height: min(700px, 90vw);
  margin-bottom: 20px;
  background-position: 50% 50%;
  background-size: 100%;
  background-repeat: no-repeat;
}

.ArticleCategoryText {
  color: #423E3A;
  font-family: 'Nunito', sans-serif;
  font-size: 16pt;
}

@media (max-width: 800px) {
  .ArticleSection {
    padding: 40px;
  }
  
  .ArticleSection h1 {
    font-size: 36px;
  }

  .ArticleSection h2 {
    font-size: 30px;
  }
}

