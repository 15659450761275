.MapSearchResult {
  margin: 10px;
  padding: 10px;
  width: 390px;
  background-color: white;
  border: 2px solid #96695e;
  border-radius: 8px;
  display: flex;
  font-family: 'Nunito', sans-serif;
  font-size: 16pt;
  flex-direction: column;
  align-items: start;
  text-align: start;
  color: black;
  text-decoration: underline;
  text-decoration-color: white;
}

a .MapSearchResult div {
  text-decoration: none;
}