.SurveySection {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
  row-gap: 20px;
  padding: 20px;
  font-family: 'Nunito', sans-serif;
  max-width: 750px;
}

.SurveyProgressBar {
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: stretch;
}

.SurveyProgressBarItem {
  height: 10px;
  flex: 1;
}

.SurveyProgressBarItemSelected {
  background-color: #96695e;
}

.SurveyProgressBarItemNotSelected {
  background-color: #96695e80;
}

.SurveyTitle {
  margin-top: 20px;
  text-align: center;
  font-size: 26pt;
  font-weight: bold;
}

.SurveySubtitle {
  margin-left: 44px;
  margin-right: 44px;
  text-align: center;
  font-size: 20pt;
  font-style: italic;
}

.SurveyContinueButton {
  background-color: black;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 5px;
  font-family: 'Nunito', sans-serif;
  font-weight: bold;
  font-size: 25pt;
  padding: 10px 40px 10px 40px;
}

.SurveyError {
  color: red;
  font-family: 'Nunito', sans-serif;
  font-size: 14pt;
  padding: 5px;
}

.SurveyTextInput {
  border-width: 1px;
  font-family: 'Nunito', sans-serif;
  margin-top: 40px;
  margin-bottom: 20px;
  font-size: 26pt;
  padding: 20px;
}

.survey-checklist {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: start;
  padding: 20px;
  width: 700px;
  text-align: start;
  font-size: 16pt;
  border: 2px solid #96695e;
  border-radius: 5px;
  overflow: hidden;
}

.selected {
  background-color: #96695e;
  color: white;
}

.notSelected {
  background-color: white;
}
