.ProviderSectionMargin {
  margin: 77px;
}

.ProviderHeaderRow {
  flex: 1;
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 32px;
}

.ProviderHeaderTitle {
  flex: 1;
  font-family: 'Sorts Mill Goudy', sans-serif;
  font-size: 30pt;
  text-align: left;
}

.ProviderHeaderLink {
  font-family: 'Nunito', sans-serif;
  font-size: 18pt;
  font-weight: 300;
}

.ProviderHeaderReviews {
  flex: 1;
  text-align: start;
  font-family: 'Nunito', sans-serif;
  font-size: 16pt;
}

.ProviderDescriptionRow {
  flex: 1;
  display: flex;
  flex-direction: row;
  margin-top: 50px;
}

.ProviderDescriptionLeft {
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 5px;
  padding: 10px;
  text-align: left;
  font-family: "Nunito", sans-serif;
  font-weight: 400;
  font-size: 20pt;
}

.ProviderHeaderReviews a {
  color: #000;
}

.ProviderBookNow {
  background-color: black;
  color: white;
  font-weight: bold;
  text-align: center;
  padding: 12px;
}

.ServicesSection {
  flex: 1;
  display: flex;
  flex-direction: column;
}

.ServiceCategoryContainer {
  display: flex;
  flex-direction: row;
  gap: 20px;
}

.ServiceCategory {
  background-color: white;
  color: black;
  border: 1px solid #000;
  padding: 16px 80px 16px 80px;
  text-align: center;
  font-family: 'Nunito', sans-serif;
  font-size: 16pt;
  font-weight: bold;
}

.ChosenServiceCategory {
  background-color: black;
  color: white;
}

.ServiceContainer {
  align-items: center;
  margin-top: 45px;
  gap: 50px;
  font-family: 'Nunito', sans-serif;
  font-size: 16pt;
}

.BookServiceButton {
  background-color: white;
  color: black;
  border: 1px solid #000;
  padding: 15px 50px 15px 50px;
  cursor: pointer;
  text-align: center;
  font-family: 'Nunito', sans-serif;
  font-size: 16pt;
  font-weight: bold;
}

.BookServiceButton:hover {
  background-color: #423E3A;
  color: white;
  cursor: pointer;
}

.ProviderBioSection {
  flex: 1;
  padding-left: 41px;
  text-align: start;
}

.ProviderBioSection h2 {
  font-family: 'Sorts Mill Goudy', sans-serif;
  font-size: 30pt;
  font-weight: normal;
}

.ProviderBioSection p {
  font-family: 'Nunito', sans-serif;
  font-size: 16pt;
}

.ProviderBioSection a {
  color: #fff;
}

.ProviderDetails {
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 6px;
}

.ProviderDetailsImage {
  margin: 30px;
  height: 68px;
}

.ProviderDetailsHeader {
  font-weight: bold;
  font-family: 'Nunito', sans-serif;
  font-size: 16pt;
}

.ProviderDetailsText {
  font-family: 'Nunito', sans-serif;
  font-weight: 300;
  font-size: 16pt;
  max-width: 380px;
}

.ProviderDetailsText a {
  font-family: 'Nunito', sans-serif;
  font-weight: 300;
  font-size: 16pt;
  max-width: 380px;
  color: white;
}

.ProviderReviewContainer {
  flex: 1;
  display: flex;
  flex-direction: row;
  margin: 50px;
  flex-wrap: wrap;
  gap: 60px;
  justify-content: center;
}

.ProviderReview {
  display: flex;
  flex-direction: column;
  width: 520px;
  font-family: 'Nunito', sans-serif;
  gap: 12px;
  font-size: 16pt;
  text-align: left;
}

.ReviewerImage {
  width: 40px;
  height: 40px;
  border-radius: 20px;
}

.MapRight {
  margin-left: 30px;
  margin-right: 30px;
  text-align: left;
  font-family: 'Nunito', sans-serif;
  font-size: 16px;
  gap: 5px;
}

.MapRight h3 {
  margin-top: 50px;
}